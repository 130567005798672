<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('programs.programsRonDonMiguel')"></banner>
    <v-container fluid class="pa-lg-16 pa-10">
      <v-row class="mx-lg-14">
        <v-row class="ma-0 justify-center justify-lg-start">
          <h2 class="copperplate h1 text-center text-lg-left">
            <span class="josefin-medium h1"> Ron Don Miguel</span>
            <br />
            Private Barrel
          </h2>
        </v-row>
        <v-row class="mx-0">
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.privateBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="justify-center scroll">
          <v-col cols="12" lg="7">
            <v-card class="rounded-0 elevation-0">
              <v-card-title class="copperplate back-gold py-1">
                {{ $t("programs.dimensionAndWeight") }}
              </v-card-title>
              <v-simple-table class="tabla d-flex">
                <template v-slot:default>
                  <thead>
                    <tr class="copperplate">
                      <th class="text-center">
                        {{ $t("programs.description") }}
                      </th>
                      <th class="text-center">
                        4L
                      </th>
                      <th class="text-center">
                        8L
                      </th>
                      <th class="text-center">
                        16L
                      </th>
                      <th class="text-center">
                        32L
                      </th>
                      <th class="text-center">
                        64L
                      </th>
                      <th class="text-center">
                        128L
                      </th>
                      <th class="text-center">
                        200L
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in desserts"
                      :key="item.Description"
                      class="josefin-medium p text-center"
                    >
                      <td class="copperplate pa-2 font-weight-bold">
                        {{ $t(item.Description) }}
                      </td>
                      <td v-html="item.l4"></td>
                      <td v-html="item.l8"></td>
                      <td v-html="item.l16"></td>
                      <td v-html="item.l32"></td>
                      <td v-html="item.l64"></td>
                      <td v-html="item.l128"></td>
                      <td v-html="item.l200"></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-row>
      <v-img src="/barriles-about.jpg" width="100%" height="400"></v-img>
    </v-row>
    <v-row class="odd px-lg-14">
      <v-container class="pa-lg-16 pa-10">
        <h2 class="copperplate h2 mb-5 text-center text-lg-left">
          {{ $t("programs.frequentQuestions") }}
        </h2>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.timeOfBarrel") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.timeOfBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">{{ $t("programs.refill") }}</h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.refillDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.maintenanceOfBarrel") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.maintenanceOfBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.howCanICareMyBarrel") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.howCanICareMyBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.emptyMyBarrel") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.emptyMyBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.whereIShouldHaveMyBarrel") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.whereIShouldHaveMyBarrelDescription')"
          ></p>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <h2 class="copperplate h2 text-center text-lg-left">
              {{ $t("programs.deliveryTime") }}
            </h2>
          </v-col>
          <p
            class="josefin-light p text-center text-lg-left"
            v-html="$t('programs.deliveryTimeDescription')"
          ></p>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="px-lg-14">
      <v-container class="pa-lg-16 pa-10">
        <p
          class="josefin-light p text-center text-lg-left"
          v-html="$t('programs.moreInformation2')"
        ></p>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner
  },
  data() {
    return {
      desserts: [
        {
          Description: "programs.a",
          l4: "27+3 <br/> cm",
          l8: "32+5 <br/> cm",
          l16: "40+5 <br/> cm",
          l32: "50+7 <br/>cm",
          l64: "60+7 <br/>cm",
          l128: "80+20 <br/>cm",
          l200: "95+20 <br/>cm"
        },
        {
          Description: "programs.b",
          l4: "17 <br/> cm",
          l8: "20 <br/> cm",
          l16: "25.5 <br/> cm",
          l32: "31 <br/>cm",
          l64: "40 <br/>cm",
          l128: "48 <br/>cm",
          l200: "55 <br/>cm"
        },
        {
          Description: "programs.c",
          l4: "19 <br/> cm",
          l8: "23 <br/> cm",
          l16: "32 <br/> cm",
          l32: "38 <br/>cm",
          l64: "50 <br/>cm",
          l128: "62 <br/>cm",
          l200: "65 <br/>cm"
        },
        {
          Description: "programs.d",
          l4: "26 <br/> cm",
          l8: "33 <br/> cm",
          l16: "40 <br/> cm",
          l32: "50 <br/>cm",
          l64: "60 <br/>cm",
          l128: "-",
          l200: "-"
        },
        {
          Description: "programs.emptyWeight",
          l4: "9 <br/> Lbs.",
          l8: "11 <br/> Lbs.",
          l16: "13 <br/> Lbs.",
          l32: "20 <br/>Lbs.",
          l64: "33 <br/>Lbs.",
          l128: "66 <br/>Lbs.",
          l200: "77 <br/>Lbs."
        },
        {
          Description: "programs.filledWeight",
          l4: "16 <br/> Lbs.",
          l8: "26 <br/> Lbs.",
          l16: "43 <br/> Lbs.",
          l32: "80 <br/>Lbs.",
          l64: "156 <br/>Lbs.",
          l128: "310 <br/>Lbs.",
          l200: "440 <br/>Lbs."
        }
      ]
    };
  },

  methods: {}
};
</script>
<style lang="scss" scoped>
.tabla tr:nth-child(even) td:nth-child(even) {
  background-color: #f2f2f2;
}
.tabla tr:nth-child(odd) td:nth-child(odd) {
  background-color: #f2f2f2;
}
.tabla tr:nth-child(odd) th:nth-child(even) {
  background-color: #f2f2f2;
}
.tabla {
  th,
  td {
    border: 1px solid white !important;
  }
}
</style>
